import axios from 'axios';
import axiosInterceptor from './axiosInterceptor/index';
import { getHeaders } from '../utils/utils';

const cancelTokenSource = {};

export const getStats = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/stats', { headers, params });
  return res.data;
};

export const getLineItemById = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/line-item', {
    headers,
    params,
  });
  return res.data;
};

export const getLineItems = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/line-items', { headers, params });
  return res.data;
};

export const getCampaignGroups = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/campaign-groups', { headers, params });
  return res.data;
};

export const getAdvertiser = async (params) => {
  cancelTokenSource.getAdvertiser = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/advertiser', {
    headers,
    params,
  });
  return res.data;
};

export const getCampaignByIds = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/campaigns-by-ids', {
    headers,
    params,
  });
  return res.data;
};

export const getCampaigns = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/campaigns', { headers });
  return res.data;
};

export const getCampaignsByFilter = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/campaigns-by-filter', { headers, params });
  return res.data;
};

export const getCampaignsStats = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/campaigns-stats', { headers, params });
  return res.data;
};

export const getCampaign = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/stack-adapt/campaigns/${id}`, {
    headers,
  });
  return res.data;
};

export const getGeos = async (params) => {
  if (cancelTokenSource.getGeos) {
    cancelTokenSource.getGeos.cancel('canceled');
  }
  cancelTokenSource.getGeos = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/top-cities', { headers, params, cancelToken: cancelTokenSource.getGeos.token });
  return res.data;
};

export const getStates = async (params) => {
  if (cancelTokenSource.getStates) {
    cancelTokenSource.getStates.cancel('canceled');
  }
  cancelTokenSource.getStates = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/top-states', { headers, params, cancelToken: cancelTokenSource.getStates.token });
  return res.data;
};

export const getDevices = async (params) => {
  if (typeof cancelTokenSource.getDevices !== typeof undefined) {
    cancelTokenSource.getDevices.cancel('canceled');
  }
  cancelTokenSource.getDevices = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/top-devices', { headers, params, cancelToken: cancelTokenSource.getDevices.token });
  return res.data;
};

export const getTopOS = async (params) => {
  if (typeof cancelTokenSource.getDevices !== typeof undefined) {
    cancelTokenSource.getDevices.cancel('canceled');
  }
  cancelTokenSource.getDevices = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/top-os', { headers, params, cancelToken: cancelTokenSource.getDevices.token });
  return res.data;
};

export const getURLs = async (params) => {
  if (typeof cancelTokenSource.getURLs !== typeof undefined) {
    cancelTokenSource.getURLs.cancel('canceled');
  }
  cancelTokenSource.getURLs = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/top-urls', { headers, params, cancelToken: cancelTokenSource.getURLs.token });
  return res.data;
};

export const addCampaignGroup = async (data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.post('/api/stack-adapt/add-campaign-group', data, { headers });
  return res.data;
};

export const addCampaigns = async (data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.post('/api/stack-adapt/add-campaigns', data, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } });
  return res.data;
};

export const getNativeAds = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/native-ads', { headers });
  return res.data;
};

export const getAllAudience = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/audience', { headers });
  return res.data;
};

export const getLookalikePixels = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/lookalike', { headers });
  return res.data;
};

export const getReport = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/report', { headers, params: { id }, responseType: 'blob' });
  return res.data;
};

export const getInterests = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/interests', { headers });
  return res.data;
};

export const getTuneStats = async ({ filters, ...params }) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/tune/stats?${filters}`, { headers, params });
  return res.data;
};

export const getStackStat = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/stack-adapt/user-stats`, { headers });
  return res.data;
};

export const getCampaignGroupsWithClickUrl = async (params) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/stack-adapt/campaign-groups-with-click-url', { headers, params });
  return res.data;
};
