/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import { format as formatTz } from 'date-fns-tz';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

export const getHeaders = () => {
  const authToken = `${localStorage.getItem('auth_token')}`;
  return {
    JWTAuthorization: authToken || '',
  };
};

export const compareObjects = (elemA, elemB) => JSON.stringify(elemA) === JSON.stringify(elemB);

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const getHost = () => `${window.location.protocol}//${window.location.hostname}`;

export const isHS = () => window.location.hostname === 'hubspot.mowpod.com';

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const validateEmail = (email) => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const findUrlInString = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.match(urlRegex) ? str.match(urlRegex)[0] : str;
};

export const serialize = (obj, prefix) => {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? `${prefix}[${Array.isArray(obj) ? '' : p}]` : p;
      let v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
    }
  }
  return str.join('&');
};

export const prepareStatsFilters = (filters) => {
  const preparedFilters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key].values.length) {
      preparedFilters[key] = filters[key];
    }
  });
  return preparedFilters;
};

export const combineStats = ({ StackStats, TuneStats }) => {
  const combinedStats = { total_stats: {}, daily_stats: [] };
  if (!StackStats.total_stats) {
    StackStats.total_stats = { imp: 0, click: 0 };
  }
  if (Array.isArray(TuneStats.total)) {
    TuneStats.total = { Stat: { clicks: 0, impressions: 0 } };
  }
  combinedStats.total_stats.imp =
    +_.get(StackStats, 'total_stats.imp', 0) + +_.get(TuneStats, 'totals.Stat.impressions', 0);
  combinedStats.total_stats.click =
    +_.get(StackStats, 'total_stats.click', 0) + +_.get(TuneStats, 'totals.Stat.clicks', 0);

  StackStats.daily_stats = StackStats.daily_stats || [];

  let arrDates = [];
  const StackStatsClone = [...StackStats.daily_stats];
  const TuneStatsClone = [...TuneStats.data];
  while (StackStatsClone.length || TuneStatsClone.length) {
    const StackStat = StackStatsClone.shift();
    const TuneStat = TuneStatsClone.shift();
    arrDates.push(...(StackStat ? [StackStat.date] : []), ...(TuneStat ? [TuneStat?.Stat.date] : []));
  }
  arrDates = [...new Set(arrDates)];

  combinedStats.daily_stats = arrDates.reduce((accum, date) => {
    const StackStatItem = StackStats.daily_stats.find((item) => item.date === date) || {};
    const TuneStatItem = TuneStats.data.find((item) => item.Stat.date === date) || {};
    accum.push({
      date,
      click: (+StackStatItem?.click || 0) + (+TuneStatItem?.Stat?.clicks || 0),
      imp: (+StackStatItem?.imp || 0) + (+TuneStatItem?.Stat?.impressions || 0),
    });
    return accum;
  }, []);
  return combinedStats;
};

export const isInt = (n) => Number(n) === n && n % 1 === 0;

export const resize = (el) => {
  el.style.height = 'inherit';
  el.style.height = `${el.scrollHeight}px`;
};

export const removePodcastName = (name) => name.split(' : ').slice(1).join(' : ');

export const prepareJotformUrl = (jotformId, fieldMapping, data) => {
  const paramsObject = Object.keys(fieldMapping).reduce((accum, field) => {
    if (Array.isArray(fieldMapping[field])) {
      accum[field] = fieldMapping[field].reduce((str, part) => {
        str += ` ${_.get(data, part)}`;
        return str;
      }, '');
      return accum;
    }
    if (typeof fieldMapping[field] === 'function') {
      accum[field] = fieldMapping[field](data);
      return accum;
    }
    accum[field] = _.get(data, fieldMapping[field], '');
    return accum;
  }, {});
  return `https://form.jotform.com/${jotformId}?${new URLSearchParams(paramsObject).toString()}`;
};

export class DateFnsTzUtils extends DateFnsUtils {
  format(date, formatString) {
    return formatTz(date, formatString, {
      timeZone: 'America/New_York',
      locale: this.locale,
    });
  }
}

export const formatDateToYYYYMMDD = (dateString) => moment(dateString).format('YYYY-MM-DD');

export const removeHTMLFromString = (string) => {
  const doc = string && new DOMParser().parseFromString(string, 'text/html');
  return doc.body.textContent;
};
