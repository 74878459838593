import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

moment.tz.setDefault('America/New_York');

const DateChooser = ({ title, field }) => {
  const { setFieldValue, values } = useContext(FormikContext);
  const handleDate = (field, val) => {
    setFieldValue(field, moment(val).format());
  };

  return (
    <>
      <Typography variant="h5">{title}</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            timezone="America/New_York"
            value={moment(values[field])}
            onChange={(value) => handleDate(field, value)}
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};

export default DateChooser;
