import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { selectStats } from '../../redux/slices/statsSlice';

const useStyles = makeStyles({
  title: { fontSize: 26, color: '#1998c4', fontWeight: 'bold' },
  img: { width: '100%', padding: 10 },
});

const ExamplePlacements = () => {
  const classes = useStyles();
  const { examplePlacement } = useSelector(selectStats);
  return (
    <>
      <Grid container item md={12} alignItems="center">
        <Typography className={classes.title}>Example Placements</Typography>
      </Grid>
      {examplePlacement.map((item) => (
        <Grid
          key={item.creatives[0].s3Url}
          container
          item
          md={4}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography>
            <b>
              {item.creatives[0].width}x{item.creatives[0].height}
            </b>
          </Typography>
          <img
            className={classes.img}
            src={item.creatives[0].s3Url}
            alt={item.creatives[0].s3Url}
          />
        </Grid>
      ))}
    </>
  );
};

export default ExamplePlacements;
