/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { getAdvertiser } from '../../api/stats';

import './style.css';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

export function TagInput({
  advIds, setAdvIds, handleTagClick,
}) {
  const handleDelete = (i) => {
    const data = advIds.filter((tag, index) => index !== i);
    setAdvIds(() => data);
  };

  const handleAddition = (tag) => {
    tag.name = (<CircularProgress size={20} />);
    setAdvIds((prev) => [...prev, tag]);
    getAdvertiser({ id: tag.id })
      .then((advertiser) => {
        setAdvIds((prev) => {
          const copyTag = [...prev];
          const currentTag = copyTag.pop();
          currentTag.text = advertiser.name;
          const advName = `${advertiser.name.split(':')[0]} (${advertiser.id})`;
          currentTag.name = advName;
          const data = [...copyTag, currentTag];
          return data;
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setAdvIds((prev) => {
          const copyTag = [...prev];
          copyTag.pop();
          return [...copyTag];
        });
      });
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  return (
    <ReactTags
      tags={advIds}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      inputFieldPosition="bottom"
      labelField="name"
      handleTagClick={handleTagClick}
      allowDeleteFromEmptyInput={false}
      allowDragDrop={false}
      placeholder="Press enter to add new id"
      autocomplete
    />
  );
}
