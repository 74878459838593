/* eslint-disable no-use-before-define */
import { Button, Grid, Box, Select, MenuItem, FormControl } from '@mui/material';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './styles';
import { addPodcast, editPodcast } from '../../api/users';
import AddPodcast from '../../components/AddPodcast/AddPodcast';
import { validationSchemaAddPodcast } from '../../components/AddPodcast/validationSchema';
import { initialValuesAddPodcast } from '../../components/AddPodcast/initialValues';
import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import redFlagIcon from '../../assets/images/red_flag.png';
import { usePodcasts } from '../../contexts/PodcastsContext';
import { useGettingData } from '../../hooks/useGettingData';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';
import { selectUsers } from '../../redux/slices/usersSlice';
import { removeFeed, selectRssFeed } from '../../redux/slices/rssSlice';

export default function Podcast() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const history = useHistory();
  const { id, podcastId } = useParams();
  const { pickedUser } = useSelector(selectUsers);
  const { isAdmin } = useSelector(selectLoginedUser);
  const { error } = useSelector(selectRssFeed);
  const [hasAdvertiserIds, setHasAdvertiserIds] = useState(false);
  const { setShow } = usePodcasts();
  const [loading, setLoading] = useState(false);
  const [checkedAdv, setCheckedAdv] = useState();
  const [selectedAdvertiserId, setSelectedAdvertiserId] = useState();
  const { handleGettingUser } = useGettingData();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValuesAddPodcast,
    validationSchema: validationSchemaAddPodcast,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: () => {
      setLoading(true);
      const { values } = formik;
      const formData = getFormData(values);
      if (podcastId) {
        return editPodcast({ userId: id, podcastId, data: formData })
          .then(() => {
            handleGettingUser(pickedUser._id);
            setLoading(false);
            history.push(id ? `/users/${id}/podcasts` : '/podcasts');
          })
          .catch((err) => {
            const errors = {};
            if (err.response.data) {
              err.response.data.forEach((e) => {
                errors[e.param] = e.msg;
              });
              formik.setErrors(errors);
            }
            console.error(err);
          });
      }
      if (id) {
        formData.append('userId', id);
      }
      return addPodcast(formData)
        .then(() => {
          handleGettingUser(pickedUser._id);
          setLoading(false);
          history.push(id ? `/users/${id}/podcasts` : '/podcasts');
        })
        .catch((err) => {
          const errors = {};
          if (err.response.data) {
            err.response.data.forEach((e) => {
              errors[e.param] = e.msg;
            });
            formik.setErrors(errors);
          }
          console.error(err);
        });
    },
  });

  useEffect(() => {
    setShow(true);
    return () => {
      formik.resetForm();
      dispatch(removeFeed());
    };
  }, []);

  function getFormData(values) {
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      if (item === 'podcastFiles') {
        const existedFiles = [];
        values[item].forEach((file, i) => {
          if (file instanceof File) {
            formData.append(`file${i}`, file);
          } else {
            existedFiles.push(file);
          }
        });
        formData.append(item, JSON.stringify(existedFiles));
        return;
      }
      formData.append(item, typeof values[item] === 'object' ? JSON.stringify(values[item]) : values[item]);
    });
    return formData;
  }

  useEffect(() => {
    if (pickedUser) {
      const { stackAdaptAdvertiser, podcasts } = pickedUser;
      const podcast = podcasts.find((item) => item._id === podcastId);
      setHasAdvertiserIds(!!stackAdaptAdvertiser.length);
      const advertiser = podcast?.advertiser || (!!stackAdaptAdvertiser.length && stackAdaptAdvertiser[0]);

      setCheckedAdv(advertiser);
      setSelectedAdvertiserId(advertiser.id);
      if (podcast) formik.setValues(podcast);
    }
  }, [pickedUser, podcastId]);

  const handleCreateCampaignGroup = (creatorType) => {
    history.push(
      `/users/${id}/podcasts/${podcastId}/advertiser/${checkedAdv.id}/campaign-group/add/${creatorType || ''}`
    );
  };

  const handleAdvertiserId = async (e) => {
    const { value } = e.target;
    setSelectedAdvertiserId(value);
    const { stackAdaptAdvertiser } = pickedUser;
    const advertiser = stackAdaptAdvertiser.find((item) => +value === +item.id);
    setCheckedAdv(advertiser);
    const formData = getFormData({ advertiser });
    await editPodcast({ userId: id, podcastId, data: formData });
    await handleGettingUser(id);
  };

  const handleListCampaignGroup = () => {
    history.push(`/users/${id}/advertiser/${checkedAdv.id}/campaign-group/list`);
  };

  const { errors } = formik;

  const getPrePath = () => (isAdmin ? `/users/${id}` : '');

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={cx('register-form', classes.podcastForm)}
      >
        <Grid
          container
          item
          md={12}
          className={classes.wrapper}
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <div className="register-step-title">
            {!podcastId ? (
              'Add a Podcast'
            ) : (
              <>
                <>Modify Your Podcast |&nbsp;</>
                <>
                  <Link to={`${getPrePath()}/podcasts/${podcastId}/advertiser/${checkedAdv?.id}/stats`}>
                    View Stats
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`${getPrePath()}/podcasts/${podcastId}/add-campaign`}>Add Campaign</Link>
                </>
              </>
            )}
          </div>
          {isAdmin && podcastId && (
            <>
              <div className="register-form-row">
                <div className={cx('register-form-block')}>
                  <Grid container className={classes.adIds} item md={12} alignItems="center">
                    <Box pt={1} pr={1}>
                      <Grid container item md={12} alignItems="center">
                        <Box pr={1}>
                          <span className={classes.label}>MowDSP Advertiser:</span>
                        </Box>
                        {hasAdvertiserIds && pickedUser.stackAdaptAdvertiser.length > 1 && (
                          <FormControl variant="outlined">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedAdvertiserId}
                              onChange={handleAdvertiserId}
                            >
                              {pickedUser.stackAdaptAdvertiser.map((item) => (
                                <MenuItem key={item.id} value={item.id} data-advertiser={item}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {hasAdvertiserIds && pickedUser.stackAdaptAdvertiser.length === 1 && (
                          <span className={classes.label}>{checkedAdv.name}</span>
                        )}
                        {!hasAdvertiserIds && (
                          <Link
                            className={classes.setupLink}
                            to={`/users/${id}/info/edit?highlight=stackAdaptAdvertiser`}
                          >
                            Setup <img src={redFlagIcon} alt="red-flag" />
                          </Link>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid container className={classes.adIds} item md={12} alignItems="center">
                    <Box pt={1} pr={1}>
                      <Button
                        onClick={() => handleCreateCampaignGroup()}
                        classes={{ disabled: classes.disabledButton }}
                        disabled={!hasAdvertiserIds || !!error || !!errors.podcastUrl}
                        className={classes.advMenu}
                      >
                        Create Campaign Group
                      </Button>
                    </Box>
                    <Box pt={1} pr={1}>
                      <Button
                        onClick={() => handleCreateCampaignGroup('sidecar')}
                        className={classes.advMenu}
                        disabled={!hasAdvertiserIds || !!error || !!errors.podcastUrl}
                        classes={{ disabled: classes.disabledButton }}
                      >
                        Create Campaign Group for Apple subs
                      </Button>
                    </Box>
                    <Box pt={1} pr={1}>
                      <Button
                        onClick={handleListCampaignGroup}
                        disabled={!hasAdvertiserIds || !!error || !!errors.podcastUrl}
                        className={classes.advMenu}
                        classes={{ disabled: classes.disabledButton }}
                      >
                        Campaign Group List
                      </Button>
                    </Box>
                  </Grid>
                </div>
              </div>
            </>
          )}
          <AddPodcast />
          <Grid
            container
            className={classes.btnSection}
            item
            md={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              className={cx(classes.button, classes.gray)}
              onClick={() => history.goBack()}
              type="button"
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={loading} className={cx(classes.button)}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
}
