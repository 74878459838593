import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import './assets/styles/styles.scss';
import Login from './pages/Login';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPassword from './components/ResetPassword';
import ConfirmEmail from './components/ConfirmEmail';
import { PublicRoute } from './components/PublicRoute/PublicRoute';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { Main } from './pages/Main';
import theme from './theme';
import { selectLoginedUser } from './redux/slices/loginedUserSlice';
import { useGettingData } from './hooks/useGettingData';

function App() {
  const { handleGettingLoginedUser } = useGettingData();
  const { user } = useSelector(selectLoginedUser);
  useEffect(() => {
    handleGettingLoginedUser();
  }, []);

  return (
    <>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Switch>
              <PublicRoute component={Login} path="/login" exact />
              <PublicRoute component={ForgotPasswordForm} path="/forgot-password" exact />
              <PublicRoute component={ResetPassword} isPublic path="/reset-password" exact />
              <PublicRoute component={ConfirmEmail} isPublic path="/confirm-email" exact />
              <PublicRoute component={RegisterPage} path="/register" exact />
              {user && <PrivateRoute component={Main} path="/" />}
            </Switch>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
