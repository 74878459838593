import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 32,
    lineHeight: '30px',
    fontWeight: '600',
    color: '#fff',
  },
  wrapper: {
    marginBottom: 20,
    padding: 20,
    backgroundColor: '#1c1b1b',
    borderRadius: 18,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  inputSection: {
    position: 'relative',
    paddingRight: 20,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px',
    },
  },
  podcastForm: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px'
    },
  },
  dropZone: {
    background: 'rgba(0,0,0,0.3)',
    width: '100%',
    height: 100,
    borderRadius: 15,
    padding: '15px 20px 15px 20px',
  },
  preview: {
    padding: 15,
  },
  deleteBtn: {
    color: '#fff',
  },
  errorIcon: {
    position: 'absolute',
    top: 13,
    right: 26,
    textAlign: 'center',
    width: 24,
    borderRadius: '50%',
    border: '#f00 solid 2px',
    color: '#f00',
    fontWeight: 'bold',
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
  },
  checkBoxLabel: {
    color: '#fff',
  },
  btnSection: {
    padding: '0 27px 25px',
  },
  button: {
    cursor: 'pointer',
    width: 150,
    height: 50,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 5,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 14,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  gray: {
    background: (props) => `url("${props.bgBtnGray}") no-repeat`,
  },
  underline: {
    "&:before": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "1px solid #fff",
    },
  },
  select: {
    color: '#000',
    width: '100%',
  },
  textarea: {
    paddingTop: 25,
    paddingBottom: 25,
    maxHeight: 200,
  },
  advMenu: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  img: {
    width: '100%',
    height: 'fit-content',
  },
  EBButton: {
    fontSize: 12,
    paddingLeft: 7,
    paddingRight: 7,
    textShadow: '0px 0px 4px #000, 0px 0px 4px #000, 0px 0px 4px #000',
  },
  active: {
    marginLeft: 5,
    width: 7,
    height: 7,
    borderRadius: '50%',
    background: 'green',
    boxShadow: '0px 0px 3px 1px rgba(255,255,255,1)',
  },
  paddingForError: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    },
  },
  btn: {
    backgroundColor: '#f56302',
    '&:hover': {
      background: '#f56302',
    },
  },
  origBtnLabel: {
    color: '#000',
  },
  disabled: {
    backgroundColor: '#c6c6c67d !important',
  },
  backdrop: {
    zIndex: 9999,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  backdropText: {
    paddingTop: 15,
    fontSize: 18,
    fontWeight: 700,
  }
}));
