import { Button, Grid, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import { useStyle } from './styles';
import { initialValues } from './initialValues';
import { getUserById, setPricing } from '../../api/users';
import { usePodcasts } from '../../contexts/PodcastsContext';

export default function Pricing() {
  const classes = useStyle();
  const { id } = useParams();
  const { setUserId, setShow, loadPodcasts } = usePodcasts();
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await setPricing(id, values);
    },
  });

  useEffect(() => {
    setShow(true);
    setUserId(id);
    loadPodcasts();
    getUserById(id).then((user) => formik.setValues(user.pricing));
  }, []);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="register-form-container">
        <Grid className={classes.dataSection} container item md={12} justifyContent="space-between">
          <Grid container direction="row" item md={6}>
            <Grid container direction="row" item md={12}>
              <Typography className={classes.fieldTitle} variant="h6">
                Boost
              </Typography>
            </Grid>
            <Grid className={classes.inputSection} container item md={10}>
              <Field type="number" name="boost" className="register-form-input" />
            </Grid>
          </Grid>
          <Grid container direction="row" item md={6}>
            <Grid container direction="row" item md={12}>
              <Typography className={classes.fieldTitle} variant="h6">
                Apple Sub
              </Typography>
            </Grid>
            <Grid className={classes.inputSection} container item md={10}>
              <Field type="number" name="appleSub" className="register-form-input" />
            </Grid>
          </Grid>
          <Grid container direction="row" item md={6}>
            <Grid container direction="row" item md={12}>
              <Typography className={classes.fieldTitle} variant="h6">
                Direct
              </Typography>
            </Grid>
            <Grid className={classes.inputSection} container item md={10}>
              <Field type="number" name="direct" className="register-form-input" />
            </Grid>
          </Grid>
          <Grid container direction="row" item md={6}>
            <Grid container direction="row" item md={12}>
              <Typography className={classes.fieldTitle} variant="h6">
                Audio
              </Typography>
            </Grid>
            <Grid className={classes.inputSection} container item md={10}>
              <Field type="number" name="audio" className="register-form-input" />
            </Grid>
          </Grid>
          <Grid className={classes.btnSection} container item md={11} justifyContent="center">
            <Button
              className={cx('button register-form-button', classes.btn)}
              fullWidth
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
}
