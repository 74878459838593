/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import { Button, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useStyles } from './styles';
import { getStats, getReport, getTuneStats } from '../../api/stats';
import TopOS from './charts/TopOS';
import TopStates from './TopStates';
import StatsTable from './StatsTable';
import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import { usePodcasts } from '../../contexts/PodcastsContext';
import Chart from './chart';
import { combineStats, prepareStatsFilters, serialize } from '../../utils/utils';
import { selectUsers } from '../../redux/slices/usersSlice';
import { useGettingData } from '../../hooks/useGettingData';
import { resetStats, selectStats } from '../../redux/slices/statsSlice';
import ExamplePlacements from '../ExamplePlacements';
import { periods } from './variables';
import { selectRssFeed } from '../../redux/slices/rssSlice';

export default function PodcastStats() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const dispatch = useDispatch();
  const { pickedUser } = useSelector(selectUsers);
  const { campaignGroups } = useSelector(selectStats);
  const { feed, loading: loadingFeed } = useSelector(selectRssFeed);

  const { handleGettingCampaignGroupsForStats, handleGettingStats, handleGettingRssFeed } =
    useGettingData();
  const [currentPodcast, setCurrentPodcast] = useState({});
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const { podcastId, advertiserId } = useParams();
  const [stats, setStats] = useState({});

  const [campGroups, setCampGroups] = useState([]);
  const [stackAdaptId, setStackAdaptId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [selected, setSelected] = useState('all');
  const { setShow } = usePodcasts();
  const [loadingReport, setLoadingReport] = useState(false);
  const [sidecar, setSidecar] = useState(false);
  const [filters, setFilters] = useState({
    'Stat.date': {
      conditional: 'BETWEEN',
      values: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    },
  });

  useEffect(() => () => dispatch(resetStats()), []);

  // const getStatistics = async (data) => {
  //   setLoading(true);
  //   const preparedFilters = prepareStatsFilters(filters);
  //   const strFilters = serialize({ filters: preparedFilters });
  //   const [StackStats, TuneStats] = await Promise.all([
  //     getStats(data),
  //     ...(sidecar ? [getTuneStats({ filters: strFilters })] : []),
  //   ]);
  //   let statsData = StackStats;
  //   if (TuneStats) {
  //     statsData = combineStats({ StackStats, TuneStats });
  //   }
  //   setLoading(false);
  // };

  useEffect(async () => {
    if (!campaignGroups) return;
    setCampGroups(() => campaignGroups);
    const campaignGroupIds = campaignGroups.map((item) => item.id);
    if (campaignGroupIds.length === 1) {
      setSelected(campaignGroupIds[0]);
      setStackAdaptId(campaignGroupIds);
      return;
    }
    setStackAdaptId(campaignGroupIds);
  }, [campaignGroups]);

  useEffect(async () => {
    setShow(true);
    setLoading(true);
    if (podcastId && pickedUser) {
      const { podcasts } = pickedUser;
      const podcast = podcasts.find((item) => item._id === podcastId);

      handleGettingRssFeed(podcast.podcastUrl);

      const { podcastName } = podcast;
      setFilters((prevState) => ({
        ...prevState,
        'Offer.name': {
          conditional: 'EQUAL_TO',
          values: [`${podcastName} Apple Sub`],
        },
      }));
      setCurrentPodcast(podcast);

      await handleGettingCampaignGroupsForStats({
        advertiserIds: [advertiserId],
        nameOrIdContains: podcastName.split(':')[0],
      });
      setLoading(false);
    }
  }, [pickedUser]);

  useEffect(() => {
    if (feed && !loadingFeed) {
      setImg(feed.image.url);
    }
  }, [feed]);

  useEffect(() => {
    if (stackAdaptId.length) {
      handleGettingStats({
        campaignGroupIds: stackAdaptId,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
      });
    }
  }, [startDate, endDate, stackAdaptId]);

  const handleStartDateChange = (value) => {
    const date = moment(value);
    setStartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      'Stat.date': {
        conditional: 'BETWEEN',
        values: [moment(value).format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
      },
    }));
    setOpenStartDate(false);
    setOpenEndDate(true);
  };

  const handleEndDateChange = (value) => {
    const date = moment(value);
    setEndDate(date);
    setOpenEndDate(false);
  };

  const handleAdvertiser = (e) => {
    const { value } = e.target;
    if (value === 'all') {
      setSelected(value);
      setStackAdaptId(() => campGroups?.map((item) => item.id));
      return;
    }
    setSelected(value);
    setStackAdaptId(() => [value]);
  };

  const setDateInterval = ({ start, end }) => {
    setStartDate(() => start);
    setEndDate(() => end);
    setFilters((prevState) => ({
      ...prevState,
      'Stat.date': {
        conditional: 'BETWEEN',
        values: [start, end],
      },
    }));
  };

  const downloadReport = () => {
    setLoadingReport(true);
    getReport(stackAdaptId[0])
      .then((response) => {
        setLoadingReport(false);
        const campGr = campGroups?.find((item) => stackAdaptId.includes(item.id));
        let url = window.URL.createObjectURL(new Blob([response]));
        let a = document.createElement('a');
        a.href = url;
        a.download = `report_${campGr.name.replace(/[^a-zA-Z]/g, '_').trim()}_mowpod.xlsx`;
        a.click();
      })
      .catch(() => setLoadingReport(false));
  };

  const isReportDisabled = loadingReport || stackAdaptId.length !== 1;

  const getEpisode = (text) => text.split(':')[1] || text;
  return (
    <Grid container item md={12} className={classes.wrapper}>
      {/* Podcast Name */}
      <Grid className={classes.wrapperTitle} container direction="row" item md={6}>
        <Typography className={classes.title} variant="h5">
          Podcast: {currentPodcast.podcastName}
        </Typography>
      </Grid>
      <Grid
        className={classes.wrapperTitle}
        container
        item
        md={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="contained"
          disabled={isReportDisabled}
          onClick={downloadReport}
          className={cx(classes.button, {
            [classes.gray]: isReportDisabled,
          })}
        >
          PRINT REPORT
        </Button>
      </Grid>
      <Grid container item md={7} className={classes.podcastMainStats}>
        {/* Podcast Img, Periods */}
        <Grid className={classes.wrapperStats} container item md={12} alignItems="center">
          <Grid container style={{ paddingRight: 15 }} item md={3} alignItems="center">
            {img && <img src={img} style={{ width: '100%' }} alt={currentPodcast.podcastName} />}
          </Grid>
          <Grid container item md={9} justifyContent="space-between" alignItems="center">
            <Grid container className={classes.datePicker} item md={5} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label="Start" value={startDate} onChange={handleStartDateChange} />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid container className={classes.datePicker} item md={5} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label="End" value={endDate} onChange={handleEndDateChange} />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid
              container
              style={{ marginTop: 15 }}
              item
              md={12}
              justifyContent="space-between"
              alignItems="center"
            >
              {periods.map((item, i) => {
                const { start, end } = item.getTimeInterval();
                const active = !moment(endDate).diff(end, 'days') && !moment(startDate).diff(start, 'days');
                return (
                  <Button
                    key={i}
                    variant="contained"
                    className={cx(classes.periodBtn, { active })}
                    type="button"
                    onClick={() => setDateInterval(item.getTimeInterval())}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {/* Select Campaign */}
        <Grid container item md={12} alignItems="center">
          <FormControl variant="outlined" className={classes.formControl}>
            <Select value={selected} onChange={handleAdvertiser}>
              {campGroups.length > 1 && <MenuItem value="all">All Campaigns</MenuItem>}
              {campGroups.map((item, i) => (
                <MenuItem key={`${item}-${i}`} value={item.id}>
                  {getEpisode(item.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Chart */}
        <Grid
          className={classes.wrapperStats}
          container
          item
          md={12}
          justifyContent="space-around"
          alignItems="flex-end"
        >
          <Chart />
        </Grid>
        {/* Stats Table */}
        <Grid
          className={classes.wrapperStats}
          style={{ marginBottom: 0 }}
          container
          item
          md={12}
          justifyContent="flex-end"
        >
          <StatsTable loading={loading} />
        </Grid>
      </Grid>
      <Grid container item md={5}>
        {/* Top cities */}
        <Grid className={classes.wrapperStats} container item md={12} alignItems="flex-start">
          <TopStates
            classes={classes}
            startDate={moment(startDate).toISOString()}
            endDate={moment(endDate).toISOString()}
            stackAdaptId={stackAdaptId}
          />
        </Grid>
        {/* Top Device Top OS */}
        <Grid container className={classes.wrapperStats} item md={12} alignItems="flex-start">
          <TopOS
            startDate={moment(startDate).toISOString()}
            endDate={moment(endDate).toISOString()}
            stackAdaptId={stackAdaptId}
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.wrapperStats}
        style={{ marginTop: 15 }}
        container
        item
        md={12}
        justifyContent="space-between"
      >
        <ExamplePlacements />
      </Grid>
    </Grid>
  );
}
